.sobre-nos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.sobre-nos-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px; /* Ajuste o valor conforme necessário */
    margin: 0 auto;
    gap: 20px; /* Espaçamento entre colunas */
}

.coluna {
    flex: 1;
    padding: 20px;
}

.coluna.esquerda {
    flex: 2;
}

.coluna.direita {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-image-sobre-nos {
    width: 100%;
    height: 800px; /* Ajusta a altura automaticamente para manter a proporção */
    object-fit: cover;
    border-radius: 8px; /* Adicione bordas arredondadas se desejar */
}

/* Estilos responsivos */
@media (max-width: 1024px) {
    .sobre-nos-container {
        flex-direction: column;
    }

    .coluna.esquerda,
    .coluna.direita {
        flex: 1; /* Igualando o tamanho das colunas em dispositivos menores */
        padding: 10px; /* Menos padding em dispositivos menores */
    }

    .banner-image-sobre-nos {
        height: auto; /* Ajusta a altura automaticamente */
    }
}

@media (max-width: 768px) {
    .banner-image-sobre-nos {
        height: 600px; /* Ajuste a altura para tamanhos de tela menores */
    }
}

@media (max-width: 480px) {
    .banner-image-sobre-nos {
        height: 480; /* Ajuste a altura para tamanhos de tela menores */
    }
}
