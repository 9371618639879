.form-container {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    /* Ocupa pelo menos a altura total da tela */
    padding: 20px;
    box-sizing: border-box;
}

.form-orcamento {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    /* Largura máxima do formulário */
}

.form-orcamento label {
    display: flex;
    font-weight: bold;
    padding-top: 2%;
}

.form-orcamento input,
.form-orcamento textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.orcamento-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.orcamento-btn a {
    color: white;
    text-decoration: none;
}