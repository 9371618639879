.banner-header {
    width: 100%;
    height: 30vh; /* 5% da altura da viewport */
    background-color: #333; /* Cor de fundo de fallback, caso a imagem não carregue */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Garante que a imagem não ultrapasse o container */
}

.banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem cubra todo o espaço disponível */
    object-position: center; /* Centraliza a imagem no banner */
}
@media (max-width: 768px) {
    .descricao-trabalhos, .nossos-servicos {
        margin-bottom: 20px; /* Ajuste a margem inferior para garantir espaçamento suficiente */
    }
  
    .custom-container {
        height: auto; /* Ajusta a altura para dispositivos móveis */
        padding: 5%; /* Ajuste o padding conforme necessário */
    }
  
    .servico-item {
        flex: 1 1 100%; /* Itens ocupam 100% da largura disponível */
        max-width: 100%; /* Remove o limite de largura máxima */
    }
  }