.fale-conosco-container {
    padding: 20px;
    max-width: 1200px;
    /* Ajuste conforme necessário */
    margin: 0 auto;
}

.fale-conosco-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    /* Espaçamento entre as colunas */
}

.coluna {
    flex: 1;
    padding: 20px;
}

.coluna.esquerda {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-image {
    max-width: 100%;
    height: auto;
    /* Ajusta a altura automaticamente para manter a proporção */
}

.coluna.direita {
    flex: 2;
}

.social-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    /* Espaçamento entre os botões */
}

/* Estilos dos botões */
.p-button-info {
    gap: 8px;
    background-color: #007bff;
    border-color: #007bff;
}

.p-button-info:hover {
    background-color: #783AB7;
    color: white;
    border-color: #0056b3;
}

.p-button-success {
    background-color: #6dc08c;
    border: none;
}

.p-button-success:hover {
    background-color: #0f5f2c;
    color: white;
    border: none;
}

/* Estilos responsivos */
@media (max-width: 1024px) {
    .fale-conosco-content {
        flex-direction: column;
        align-items: center;
    }

    .coluna {
        padding: 10px;
    }

    .logo-image {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .logo-image {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .fale-conosco-container {
        padding: 10px;
    }

    .social-buttons {
        flex-direction: column;
    }

    .social-buttons Button {
        margin-bottom: 10px;
    }
}