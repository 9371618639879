.descricao-trabalhos {
    background-image: url('../images/encanador-back.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Adicionado */
    z-index: 1; /* Adicionado */
    margin-bottom: 2%;
    margin-top: 2%;
    padding: 20px; /* Adicionado para garantir espaçamento interno */
    box-sizing: border-box; /* Adicionado para incluir padding e border no cálculo da largura/altura */
}

.custom-container {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2%;
    height: 350px;
    border-radius: 15px;
    display: inline-block;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box; /* Adicionado */
}

.custom-container h2,
.custom-container p {
    margin: 0 0 15px 0;
}

.custom-container h2 {
    font-size: 2em;
}

.custom-container p {
    font-size: 1.2em;
}

.btn-orcamento {
    margin-top: 2%;
}



.orcamento-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.orcamento-btn a {
    color: white;
    text-decoration: none;
}