.formas-pagamento-card {
    text-align: center;
    background-color: #333;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    /* Adiciona padding para espaçamento interno */
    box-sizing: border-box;
    /* Inclui padding e border no cálculo da largura/altura */
    border-radius: 10px;
    /* Adiciona bordas arredondadas */
    max-width: 100%;
    /* Garante que o card não exceda a largura da tela */
    overflow: hidden;
    /* Garante que nada "estoure" o contêiner */
}

.formas-pagamento-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-method img {
    max-width: 100%;
    /* Ajusta a largura máxima da imagem para o tamanho do contêiner */
    height: auto;
    /* Mantém a proporção da imagem */
}

@media (max-width: 768px) {
    .formas-pagamento-card {
        padding: 15px;
        /* Ajusta o padding para dispositivos móveis */
    }

    .formas-pagamento-content p {
        font-size: 1em;
        /* Ajusta o tamanho da fonte para dispositivos móveis */
    }
}