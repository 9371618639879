.nossos-servicos {
  background-color: #333;
  position: relative;
  z-index: 2;
  padding: 20px; /* Adicionado para garantir espaçamento interno */
  box-sizing: border-box; /* Adicionado para incluir padding e border no cálculo da largura/altura */
}

.servicos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.servico-item {
  flex: 1 1 calc(50% - 20px); /* Dois itens por linha com espaço entre eles */
  max-width: calc(50% - 20px);
  box-sizing: border-box; /* Adicionado */
}

.servico-card {
  height: 300px; /* Ajuste a altura conforme necessário */
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  border: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  box-sizing: border-box; /* Adicionado */
}

.servico-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-sizing: border-box; /* Adicionado */
}

